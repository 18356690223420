import React, { useEffect, useRef, useState } from "react"
import MediaPlayerGUI from "./MediaPlayerGUI"
import AudioPlayer from "./audio"
import PropTypes from "prop-types"
import playList from "../../../content/play-list"

export default function MediaPlayer({ analyserRef }) {
  const AudioContext = window.AudioContext || window.webkitAudioContext
  const [trackIndex, setTrackIndex] = useState(0)
  const [ready, setReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioPlayer = useRef()
  const audioContextRef = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [currentDuration, setCurrentDuration] = useState(0)

  // Reinstantiate AudioPlayer
  useEffect(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext({ sampleRate: 44100 })
    }
    const wasPlaying = isPlaying

    // setReady(false)
    // setIsPlaying(false)
    setCurrentTime(0)

    if (audioPlayer.current) {
      audioPlayer.current.destroy()
    }

    audioPlayer.current = new AudioPlayer({
      track: playList[trackIndex],
      audioContext: audioContextRef.current,
      playOnLoad: wasPlaying,
      onLoading: () => setReady(false),
      onReady: ({ duration }) => {
        setReady(true)
        duration && setCurrentDuration(duration)
      },
      onAnalyser: (analyser) => {
        console.log("setting analyser")
        analyserRef.current = analyser
      },
      onPlay: () => setIsPlaying(true),
      onPause: () => setIsPlaying(false),
      onProgress: (time) => setCurrentTime(time),
      onFinish: nextTrack,
    })

    return () => {
      audioPlayer.current && audioPlayer.current.destroy()
    }
  }, [trackIndex])

  useEffect(() => {
    if (isPlaying && currentTime >= currentDuration) {
      nextTrack()
    }
  }, [currentTime, currentDuration, isPlaying])

  // Control Functions
  const nextTrack = () => {
    setTrackIndex((trackIndex + 1) % playList.length)
  }

  const prevTrack = () => {
    setTrackIndex(Math.max(0, trackIndex - 1))
  }

  const changeTrack = (track) => {
    const index = playList.findIndex((t) => t.title === track.title)
    setIsPlaying(true)
    setTrackIndex(index)
  }

  const play = () => {
    audioPlayer.current.play()
  }

  const pause = () => audioPlayer.current.pause()

  return (
    <MediaPlayerGUI
      ready={ready}
      isPlaying={isPlaying}
      track={playList[trackIndex]}
      nextTrack={nextTrack}
      prevTrack={prevTrack}
      changeTrack={changeTrack}
      currentTime={currentTime}
      currentDuration={currentDuration}
      playList={playList}
      play={play}
      pause={pause}
    />
  )
}

MediaPlayer.propTypes = {
  analyserRef: PropTypes.object,
}
