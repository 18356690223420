import * as THREE from "three"
// import * as dat from "dat.gui"
import { refreshAnalyserData, parseAudioData } from "./helpers"
import WaveSystem from "./wave/WaveSystem"
import DustSystem from "./dust/DustSystem"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"

// FPS Debugging
// import Stats from "three/examples/jsm/libs/stats.module"

export default function main(analyserRef, canvasRef) {
  // Debug
  // const gui = new dat.GUI()

  // Canvas
  const canvas = canvasRef.current

  /**
   * Sizes
   */
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  // Scene
  const scene = new THREE.Scene()

  // Base camera
  const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100
  )
  camera.position.x = 0
  camera.position.y = 0
  camera.position.z = 25
  scene.add(camera)

  // Lights

  const pLight = new THREE.PointLight("yellow", 0.1)
  pLight.position.y = -10
  scene.add(pLight)

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
  })
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  /**
   * Animate
   */

  const clock = new THREE.Clock()
  const startingPosition = new THREE.Vector3(-20, -5, 0)
  const particleConfig = {
    period: 40,
    position: startingPosition,
    maxParticleSize: 0.3,
    amplitudeOffset: 2,
  }

  let dust = new DustSystem({ count: 200, scene, renderer })

  let particles = new WaveSystem({
    scene,
    camera,
    renderer,
    particleConfig,
  })

  const controls = new OrbitControls(camera, canvas)
  controls.enableDamping = true

  const composer = new EffectComposer(renderer)
  const renderPass = new RenderPass(scene, camera)
  const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(1920, 1080),
    2,
    0,
    0.5
  )
  composer.addPass(renderPass)
  composer.addPass(bloomPass)
  scene.background = new THREE.Color("#111111")

  // Listeners

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    renderer.setSize(sizes.width, sizes.height)
    composer.setSize(sizes.width, sizes.height)

    // Update renderer
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  })
  const mouse = new THREE.Vector2(0, 0)
  window.addEventListener("mousemove", (e) => {
    mouse.x = e.clientX - sizes.width / 2
    mouse.y = e.clientY - sizes.height / 2
  })

  const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const dataArray = refreshAnalyserData(analyserRef)
    const audioData = parseAudioData(dataArray)
    particles.Step(audioData)
    dust.step(elapsedTime, audioData)
    camera.position.lerp(
      new THREE.Vector3(
        (mouse.x / sizes.width) * -3,
        (mouse.y / sizes.height) * -2,
        camera.position.z
      ),
      0.1
    )

    controls.update()

    // Render
    composer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
  }

  tick()
}
