import PropTypes from "prop-types"
import React from "react"
import { animated, config, useSpring, useTrail } from "@react-spring/web"

const FadeContainer = ({
  show,
  children,
  style = {},
  trail,
  className = "",
}) => {
  if (trail) {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
      config: config.slow,
      opacity: show ? 1.0 : 0.0,
      marginLeft: show ? 0 : 40,
    })

    return (
      <div>
        {trail.map((styles, index) => (
          <animated.div
            key={`trail-item-${index}`}
            className={className}
            style={styles}
          >
            {items[index]}
          </animated.div>
        ))}
      </div>
    )
  } else {
    const fadeProps = useSpring({
      opacity: show ? 1.0 : 0.0,
      marginTop: show ? 0 : 40,
      config: config.molasses,
    })
    return (
      <animated.div style={{ ...fadeProps, ...style }}>{children}</animated.div>
    )
  }
}

FadeContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  style: PropTypes.any,
  trail: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
}

export default FadeContainer
