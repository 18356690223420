import React, { useEffect, useRef } from "react"
import OuterLayout from "../components/OuterLayout"
import SplashImage from "../components/SplashImage"
import MediaPlayer from "../components/MediaPlayer"
import initVisualizer from "../components/visualizer/main"

const isBrowser = typeof window !== "undefined"
const IndexPage = () => {
  const analyserRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (isBrowser) {
      initVisualizer(analyserRef, canvasRef)
    }
  }, [canvasRef])

  return (
    <OuterLayout
      title="Home"
      metaDescription="Official website of composer, producer, and guitarist Tim Sandberg"
      path="/"
    >
      {/* <Header /> */}
      <canvas
        className="position-absolute"
        ref={canvasRef}
        style={{ width: "100%", height: "100%", zIndex: 0, display: "block" }}
        id="canvas"
      />
      <SplashImage />
      {isBrowser && <MediaPlayer analyserRef={analyserRef} />}
    </OuterLayout>
  )
}

export default IndexPage
