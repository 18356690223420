import * as THREE from "three"
import disc from "./disc.png"
import WaveParticle from "./WaveParticle"

const VERTEX_SHADER = `
uniform float pointMultiplier;

attribute float size;
attribute vec4 colour;

varying vec4 vColour;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size * pointMultiplier / gl_Position.w;
  vColour = colour;
}`

const FRAGMENT_SHADER = `
uniform sampler2D diffuseTexture;

varying vec4 vColour;

void main() {
  gl_FragColor = texture2D(diffuseTexture, gl_PointCoord) * vColour;
}`

// const PERIOD = 10
// const STARTING_POSITION = new THREE.Vector3(-PERIOD / 2, 0, 0)
// const MAX_PARTICLE_SPEED = 0.1
// const MAX_PARTICLE_SIZE = 0.3
// const AMPLITUDE_OFFSET = 2

export default class WaveSystem {
  constructor({ camera, renderer, particleConfig, scene }) {
    const uniforms = {
      diffuseTexture: {
        value: new THREE.TextureLoader().load(disc),
      },
      pointMultiplier: {
        value:
          renderer.domElement.height /
          (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
      },
    }

    this.particleConfig = particleConfig

    this._material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: VERTEX_SHADER,
      fragmentShader: FRAGMENT_SHADER,
      blending: THREE.AdditiveBlending,
      depthTest: true,
      depthWrite: false,
      transparent: true,
      vertexColors: true,
    })

    this._particles = []
    this._maxParticles = 0 // for optimization metrics
    this._camera = camera

    this._geometry = new THREE.BufferGeometry()
    this._geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute([], 3)
    )
    this._geometry.setAttribute("size", new THREE.Float32BufferAttribute([], 1))
    this._geometry.setAttribute(
      "colour",
      new THREE.Float32BufferAttribute([], 4)
    )
    this._geometry.setAttribute(
      "angle",
      new THREE.Float32BufferAttribute([], 1)
    )

    this._points = new THREE.Points(this._geometry, this._material)

    scene.add(this._points)
    this._initialParticles()
    this._UpdateGeometry()
  }

  reset() {
    this._particles = []
  }

  _initialParticles() {
    const n = 200
    for (let i = 0; i < n; i++) {
      const position = this.particleConfig.position.clone()
      position.x = position.x + Math.random() * this.particleConfig.period
      this._addDefaultParticle(position)
    }
  }

  _addDefaultParticle(position, n = 1) {
    for (let i = 0; i < n; i++) {
      position = position || this.particleConfig.position.clone()
      position.y += (Math.random() - 0.5) * 2
      this._particles.push(
        new WaveParticle({
          ...this.particleConfig,
          position,
          baseAlpha: 0.5,
          startingPosition: position,
          amplitudeOffset: 1 + Math.random(),
          color: new THREE.Color("#FFDC97"),
          velocity: 0.04 + Math.random() * 0.02,
        })
      )
    }
  }

  /**
   * Particles based on audio data
   */
  _AddParticles(audioStats) {
    const {
      lowMaxFreq,
      lowMaxRatio,
      midMaxFreq,
      midMaxRatio,
      highMaxFreq,
      highMaxRatio,
      colorSet,
    } = audioStats

    const createMusicParticle = (
      maxFreq,
      maxRatio,
      color1,
      color2,
      n = null
    ) => {
      const volumeRatio = maxFreq / 255
      const particleCount = n || Math.floor(volumeRatio * 5)
      for (let i = 0; i < particleCount; i++) {
        const position = this.particleConfig.position.clone()

        position.y += (Math.random() - 0.5) * (4 - volumeRatio)
        this._particles.push(
          new WaveParticle({
            ...this.particleConfig,
            position,
            baseAlpha: 1,
            startingPosition: position,
            amplitudeOffset: 1 + 3 * volumeRatio * Math.random(),
            color: color1.clone().lerp(color2, maxRatio),
            velocity: volumeRatio * 0.03 + Math.random() * 0.2 * volumeRatio,
          })
        )
      }
    }

    if (highMaxFreq > 0) {
      createMusicParticle(
        highMaxFreq,
        highMaxRatio,
        colorSet.high,
        colorSet.default
      )
    }

    if (midMaxFreq > 10) {
      createMusicParticle(midMaxFreq, midMaxRatio, colorSet.med, colorSet.high)
    }
    if (lowMaxFreq > 80) {
      createMusicParticle(lowMaxFreq, lowMaxRatio, colorSet.low, colorSet.med)
    }

    if (this._particles.length < 800) {
      this._addDefaultParticle()
    }
  }

  /**
   * 1. Decrement remaining life of particle
   * 2. Filter out particles of life < 0
   * 3. Update position, color, alpha based on what % of life remains to particle
   */
  _UpdateParticles({ overallAvg }) {
    this._particles = this._particles.filter((p) => {
      return !p.isExpired()
    })

    // if (this._particles.length > this._maxParticles) {
    //   this._maxParticles = this._particles.length
    //   console.log("new max:", this._maxParticles)
    // }

    // Have particles pulse with the avg frequency of their range
    for (let p of this._particles) {
      p.update({ overallAvg })
    }
  }

  // Refresh position, size, colors, angles of BufferGeometry
  // based on particles[]
  _UpdateGeometry() {
    const positions = []
    const sizes = []
    const colors = []
    const angles = []

    for (let p of this._particles) {
      positions.push(p.position.x, p.position.y, p.position.z)
      colors.push(p.color.r, p.color.g, p.color.b, p.alpha)
      sizes.push(p.size)
      angles.push(p.rotation)
    }

    this._geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    )
    this._geometry.setAttribute(
      "size",
      new THREE.Float32BufferAttribute(sizes, 1)
    )
    this._geometry.setAttribute(
      "colour",
      new THREE.Float32BufferAttribute(colors, 4)
    )
    this._geometry.setAttribute(
      "angle",
      new THREE.Float32BufferAttribute(angles, 1)
    )

    this._geometry.attributes.position.needsUpdate = true
    this._geometry.attributes.size.needsUpdate = true
    this._geometry.attributes.colour.needsUpdate = true
    this._geometry.attributes.angle.needsUpdate = true
  }

  Step(audioData) {
    this._AddParticles(audioData)
    this._UpdateParticles({ overallAvg: audioData.overallAvg })
    this._UpdateGeometry()
  }
}
