import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { animated, useSpring } from "@react-spring/web"

// Icons
const PLAY_ICON = "fas fa-play"
const PAUSE_ICON = "fas fa-pause"
const BACK_ICON = "fas fa-step-backward"
const FORWARD_ICON = "fas fa-step-forward"
const LIST_ICON = "fas fa-list"
const CLOSE_ICON = "fas fa-times"

const MediaPlayerGUI = ({
  isPlaying,
  track,
  play,
  pause,
  nextTrack,
  currentTime,
  currentDuration,
  prevTrack,
  playList,
  changeTrack,
  ready,
}) => {
  const [playListOpen, setPlayListOpen] = useState(false)
  const progress = ((currentTime * 100) / currentDuration).toFixed(2)
  const playerHeadRef = useRef()
  const playListRef = useRef()
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (playListOpen) {
      setOffset(
        playerHeadRef.current.clientHeight + playListRef.current.clientHeight
      )
    } else {
      setOffset(playerHeadRef.current.clientHeight)
    }
  }, [playerHeadRef, playListOpen, playListRef])

  const springContainerProps = useSpring({
    top: playListOpen
      ? `calc(100% - ${offset + 30}px)`
      : `calc(100% - ${offset + 30}px)`,
  })

  return (
    <animated.div
      style={{
        bottom: "0",
        position: "fixed",
        width: "100%",
        height: "100vh",
        borderRadius: "25px 25px 0 0",
        zIndex: 300,
        ...springContainerProps,
      }}
    >
      <div className="media-player__container">
        <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: 299,
            backgroundColor: "rgba(0,0,0,0.5)",
            transition: "0.4s",
            opacity: playListOpen ? 1 : 0,
            position: "absolute",
            borderRadius: "25px 25px 0 0",
            backdropFilter: "blur(20px)",
            WebkitBackdropFilter: "blur(20px)",
          }}
        />
        <div ref={playerHeadRef} className="media-player__head-container">
          <div className="media-player__controls">
            <button
              className="media-player__button media-player__nav-button"
              onClick={prevTrack}
            >
              <i className={BACK_ICON} />
            </button>
            <button
              className={`media-player__button media-player__play-button${
                ready ? "" : " disabled"
              }`}
              onClick={isPlaying ? pause : play}
              disabled={!ready}
            >
              {ready ? (
                <i className={isPlaying ? PAUSE_ICON : PLAY_ICON} />
              ) : (
                <i className="fas fa-spinner fa-spin" />
              )}
            </button>
            <button
              className="media-player__button media-player__nav-button"
              style={{ position: "absolute", right: 0 }}
              onClick={() => setPlayListOpen(!playListOpen)}
            >
              <i className={playListOpen ? CLOSE_ICON : LIST_ICON} />
            </button>
            <button
              className="media-player__button media-player__nav-button"
              onClick={nextTrack}
            >
              <i className={FORWARD_ICON} />
            </button>
          </div>
          <div className="media-player__track-title">
            <span className="">{track.title}</span>
          </div>
          <div className="media-player__track-info">
            <span>{moment(currentTime * 1000).format("m:ss")}</span>
            <div className="media-player__progress-container">
              {ready && (
                <div
                  className="media-player__progress-bar"
                  style={{ width: `${progress}%` }}
                ></div>
              )}
            </div>
            <span>{moment(currentDuration * 1000).format("m:ss")}</span>
          </div>
        </div>
        <div
          ref={playListRef}
          className="media-player__playlist text-white pb-3"
        >
          <ul>
            {playList.map((t) => {
              const isCurrentTrack = t.title === track.title
              return (
                <li
                  key={`playlist-item-${t.title}`}
                  className={isCurrentTrack && isPlaying ? "current-track" : ""}
                >
                  <button
                    className="media-player__button media-player__nav-button"
                    onClick={() =>
                      track.title === t.title && isPlaying
                        ? pause()
                        : changeTrack(t)
                    }
                  >
                    <i
                      className={
                        isCurrentTrack && isPlaying ? PAUSE_ICON : PLAY_ICON
                      }
                    />
                  </button>
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  >
                    <img src={t.image} alt={track.alt} />
                  </div>
                  <a
                    className="btn btn-link"
                    rel="noreferrer"
                    href={t.info}
                    target="_blank"
                  >
                    {t.title}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </animated.div>
  )
}

MediaPlayerGUI.propTypes = {
  ready: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  nextTrack: PropTypes.func.isRequired,
  currentTime: PropTypes.number,
  currentDuration: PropTypes.number,
  prevTrack: PropTypes.func.isRequired,
  track: PropTypes.object,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  playList: PropTypes.array.isRequired,
  changeTrack: PropTypes.func.isRequired,
}
export default MediaPlayerGUI
