import * as THREE from "three"
import circle from "../wave/circle.png"

const VERTEX_SHADER = `
uniform float pointMultiplier;

attribute float size;
attribute vec4 colour;

varying vec4 vColour;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size * pointMultiplier / gl_Position.w;
  vColour = colour;
}`

const FRAGMENT_SHADER = `
uniform sampler2D diffuseTexture;

varying vec4 vColour;

void main() {
  gl_FragColor = texture2D(diffuseTexture, gl_PointCoord) * vColour;
}`

export default class DustSystem {
  constructor({ count, scene, renderer }) {
    const loader = new THREE.TextureLoader()
    const sprite = loader.load(circle)
    const uniforms = {
      diffuseTexture: {
        value: sprite,
      },
      pointMultiplier: {
        value:
          renderer.domElement.height /
          (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
      },
    }

    this.dustCount = count
    this.defaultAlpha = 0.1
    this.defaultColor = new THREE.Color("#FFDC97")
    this.lastBlink = 0
    this.blinkFreq = 2000
    this.material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: VERTEX_SHADER,
      fragmentShader: FRAGMENT_SHADER,
      blending: THREE.NormalBlending,
      depthTest: true,
      depthWrite: false,
      transparent: true,
    })
    this.particles = this._initParticles()
    this.geometry = this._initGeometry()

    this.mesh = new THREE.Points(this.geometry, this.material)

    scene.add(this.mesh)
  }

  _initGeometry() {
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute("colour", new THREE.Float32BufferAttribute([], 4))
    geometry.setAttribute("position", new THREE.Float32BufferAttribute([], 3))
    geometry.setAttribute("size", new THREE.Float32BufferAttribute([], 1))
    return geometry
  }

  _updateGeometry() {
    const positions = []
    const sizes = []
    const colors = []

    for (let p of this.particles) {
      positions.push(p.position.x, p.position.y, p.position.z)
      colors.push(p.color.r, p.color.g, p.color.b, p.alpha)
      sizes.push(p.size)
    }

    this.geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    )
    this.geometry.setAttribute(
      "size",
      new THREE.Float32BufferAttribute(sizes, 1)
    )
    this.geometry.setAttribute(
      "colour",
      new THREE.Float32BufferAttribute(colors, 4)
    )
    this.geometry.attributes.position.needsUpdate = true
    this.geometry.attributes.size.needsUpdate = true
    this.geometry.attributes.colour.needsUpdate = true
  }

  _initParticles() {
    const particles = []
    for (let i = 0; i < this.dustCount; i++) {
      const x = THREE.MathUtils.randFloatSpread(40)
      const y = THREE.MathUtils.randFloatSpread(40)
      const z = THREE.MathUtils.randFloatSpread(40)

      particles.push({
        position: new THREE.Vector3(x, y, z),
        color: this.defaultColor.clone(),
        size: THREE.MathUtils.randFloat(0.1, 0.7),
        alpha: this.defaultAlpha,
      })
    }

    return particles
  }

  _updateParticles({ overallAvg }) {
    const fadeSpeed = 0.1
    const threshold = 40
    const blinkCount = 2
    const getRandomParticles = (count) => {
      const indices = []
      for (let i = 0; i < count; i++) {
        let randomIndex
        while (indices.indexOf(randomIndex) !== -1) {
          randomIndex = THREE.MathUtils.randInt(0, this.particles.length)
        }
        indices.push(randomIndex)
      }
      return indices
    }
    let blinkIndices = []
    const timeNow = window.performance.now()
    this.lastBlink += timeNow - this.lastBlink
    const shouldBlink = this.lastBlink > this.blinkFreq
    if (overallAvg > threshold && shouldBlink) {
      blinkIndices = getRandomParticles(blinkCount)
      this.lastBlink = 0
    }

    for (let i = 0; i < this.particles.length; i++) {
      const p = this.particles[i]
      if (blinkIndices.indexOf(i) !== -1) {
        p.alpha = 0.5
      } else {
        p.alpha = THREE.MathUtils.lerp(p.alpha, this.defaultAlpha, fadeSpeed)
      }
    }
  }

  step(elapsedTime, audioData) {
    this.mesh.rotation.y += 0.0005
    this._updateParticles(audioData)
    this._updateGeometry()
  }
}
