import React, { useRef, useState } from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "../assets/styles/splash.scss"
import FadeContainer from "./shared/FadeContainer"
import { Link } from "gatsby"

const SplashImage = () => {
  const [fadeIn, setFadeIn] = useState(true)
  const splashRef = useRef(null)

  return (
    <div
      ref={splashRef}
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
      }}
    >
      <div className="splash-hero pb-5 pb-md-0">
        <div className="container text-light flex-row d-flex align-items-center">
          <FadeContainer show={fadeIn} classNames="ps-3" trail>
            <h5 className="font-bebas">Composer for film and media</h5>
            <h1 className="display-1 font-bebas lh-1 m-0">Tim Sandberg</h1>
            <ul className="nav m-0">
              <li className="nav-item">
                <Link className="nav-link ms-0 ps-1" to="/music">
                  Music
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link ms-0" to="/credits">
                  Credits
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </FadeContainer>
        </div>
      </div>
    </div>
  )
}

export default SplashImage
