import * as THREE from "three"

export default class WaveParticle {
  constructor({
    amplitudeOffset,
    position,
    startingPosition,
    period,
    velocity,
    color,
    baseAlpha,
    maxParticleSize,
  }) {
    this.position = position
    this.startingPosition = startingPosition
    this.period = period
    this.size = Math.random() * maxParticleSize
    this.color = color || new THREE.Color("#ffffff")
    this.amplitudeOffset = amplitudeOffset
    this.xOffset = Math.random() * 2
    this.alpha = 0
    this.baseAlpha = baseAlpha || 0.2
    this.rotation = 0
    this.velocity = velocity
    this.speedMod = 0
    this.spherePos = Math.random() > 0.5 ? -1 : 1

    this.alphaSpline = new THREE.SplineCurve([
      new THREE.Vector2(0.1, 0),
      new THREE.Vector2(0.3, 0),
      new THREE.Vector2(1, 0),
      new THREE.Vector2(0.3, 0),
      new THREE.Vector3(0.1, 0),
    ])
  }

  isExpired() {
    return this.position.x >= this.startingPosition.x + this.period + 1
  }

  // Check this :P
  _getProgress() {
    const { period, position } = this
    const endPoint = period / 2
    const percentageRemaining = (endPoint - position.x) / period
    // Cant figure out how this is returning < 0
    return Math.max(0, Math.min(1 - percentageRemaining, 1))
  }

  // function of time and amplitude
  update({ overallAvg }) {
    const { position, velocity, amplitudeOffset, xOffset, startingPosition } =
      this
    this.speedMod = THREE.MathUtils.lerp(this.speedMod, overallAvg / 255, 0.1)
    const t = this._getProgress()
    const fadeInOut = t <= 0.5 ? t : 1 - t

    this.alpha = this.alphaSpline.getPointAt(t).x * this.baseAlpha
    const x = position.x + velocity + this.speedMod
    const y =
      amplitudeOffset * this._particleCurve(x - fadeInOut * xOffset) +
      startingPosition.y
    const z = this.spherePos * Math.sin(0.5 * x) - x / 2
    this.position = new THREE.Vector3(x, y, z)
  }

  _particleCurve(x) {
    const { period } = this
    return Math.sin(((2 * Math.PI) / period) * x)
  }
}
