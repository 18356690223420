import axios from "axios"

export const FFT_SIZE = 128
export default class AudioPlayer {
  constructor({
    track,
    onReady,
    onFinish,
    onAnalyser,
    onPlay,
    onPause,
    onLoading,
    playOnLoad,
    onProgress,
    audioContext,
  }) {
    this.context = audioContext
    this.pausedAt = 0
    this.startedAt = 0
    this.track = track
    this.ready = false
    this.buffer = null
    this.source = null
    this.onPlay = onPlay
    this.onPause = onPause
    this.onReady = onReady
    this.onFinish = onFinish
    this.onLoading = onLoading
    this.onProgress = onProgress
    this.onAnalyser = onAnalyser
    this.isPlaying = false
    this.playOnLoad = playOnLoad
    this.timeInterval = null

    this.loadSong()
  }

  async loadSong() {
    const url = this.track.url
    this.onLoading()
    const { data } = await axios.get(url, { responseType: "arraybuffer" })
    const audioBuffer = await this.context.decodeAudioData(data)
    this.buffer = audioBuffer
    console.log(this.track.title, "ready")
    this.onReady({ duration: this.buffer.duration })
    this.ready = true
    if (this.playOnLoad) {
      this.play()
    }
  }

  initAnalyser() {
    // Connect analyser
    this.analyserNode = this.context.createAnalyser()
    this.analyserNode.fftSize = FFT_SIZE
    this.source.connect(this.analyserNode)
    this.onAnalyser(this.analyserNode)
  }

  destroyAnalyser() {
    this.analyserNode.disconnect()
    this.analyserNode = null
    this.onAnalyser(null)
  }

  // Destroy analyser whenever paused, init a new one every time playback is restarted

  pause() {
    const elapsed = this.context.currentTime - this.startedAt
    this._stop()
    this.pausedAt = elapsed
    this.onPause()
  }

  _stop() {
    if (this.source) {
      this.destroyAnalyser()
      this.source.disconnect()
      this.source.stop(0)
      this.source = null
    }

    this.pausedAt = 0
    this.startedAt = 0
    this.isPlaying = false
    clearInterval(this.timeInterval)
  }

  play() {
    if (!this.ready) {
      console.log("Track not ready")
      return
    }
    this.source = this.context.createBufferSource()
    this.source.buffer = this.buffer
    this.source.connect(this.context.destination)

    // Connect analyser
    this.analyserNode = this.context.createAnalyser()
    this.analyserNode.fftSize = FFT_SIZE
    this.source.connect(this.analyserNode)
    this.onAnalyser(this.analyserNode)

    // Update timers and play
    const offset = this.pausedAt
    this.source.start(0, offset)
    this.startedAt = this.context.currentTime - offset
    this.pausedAt = 0
    this.isPlaying = true
    this.onPlay()
    this.timeInterval = setInterval(this.emitCurrentTime.bind(this), 500)
  }

  destroy() {
    this._stop()
    clearInterval(this.timeInterval)
  }

  emitCurrentTime() {
    if (this.pausedAt) {
      this.onProgress(this.pausedAt)
    } else {
      const time = this.context.currentTime - this.startedAt
      this.onProgress(time)
    }
  }
}
