import { FFT_SIZE } from "../MediaPlayer/audio"
import * as THREE from "three"
//some helper functions here
function fractionate(val, minVal, maxVal) {
  return (val - minVal) / (maxVal - minVal)
}

function modulate(val, minVal, maxVal, outMin, outMax) {
  var fr = fractionate(val, minVal, maxVal)
  var delta = outMax - outMin
  return outMin + fr * delta
}

function avg(arr) {
  var total = arr.reduce(function (sum, b) {
    return sum + b
  })
  return total / arr.length
}

function max(arr) {
  return arr.reduce(function (a, b) {
    return Math.max(a, b)
  })
}
let dataArray = new Uint8Array(FFT_SIZE / 2)
let bufferLength = FFT_SIZE / 2
let analyser

let colorSetIndex = 0
const colorSets = [
  {
    low: new THREE.Color("#B2361F"),
    med: new THREE.Color("#D6923E"),
    high: new THREE.Color("#2D6789"),
    default: new THREE.Color("#FFFFFF"),
  },
  {
    low: new THREE.Color("#2D726D"),
    med: new THREE.Color("#B8494C"),
    high: new THREE.Color("#BA9249"),
    default: new THREE.Color("#FFFFFF"),
  },
  {
    low: new THREE.Color("#C4A847"),
    med: new THREE.Color("#61A93E"),
    high: new THREE.Color("#463D88"),
    default: new THREE.Color("#D7F20B"),
  },
  {
    low: new THREE.Color("#AA5B39"),
    med: new THREE.Color("#83A136"),
    high: new THREE.Color("#303C74"),
    default: new THREE.Color("#FFFFFF"),
  },
]

function getColorSet() {
  return colorSets[colorSetIndex]
}

function refreshAnalyserData(analyserRef) {
  if (analyser && analyserRef.current === null) {
    console.log("clearing analyser data")
    colorSetIndex = (colorSetIndex + 1) % colorSets.length
    dataArray = new Uint8Array(bufferLength)
    bufferLength = null
    analyser = null
  } else if (!analyser && analyserRef.current) {
    console.log("setting up analyser data")
    analyser = analyserRef.current
    bufferLength = analyser.frequencyBinCount
    dataArray = new Uint8Array(bufferLength)
    analyser.getByteFrequencyData(dataArray)
  } else if (analyser && analyserRef.current) {
    analyser.getByteFrequencyData(dataArray)
  }
  return dataArray
}

function parseAudioData(dataArray) {
  // High frequencies are full top half of the spectrum
  // Low = 0 - 25%
  // Mid = 25% - 50%
  const lowFrequencies = dataArray.slice(0, dataArray.length / 4)
  const midFrequencies = dataArray.slice(
    dataArray.length / 4,
    dataArray.length / 2
  )
  const highFrequencies = dataArray.slice(
    dataArray.length / 2,
    dataArray.length
  )

  const overallAvg = avg(dataArray)
  const lowFreqAvg = avg(lowFrequencies)
  const midFreqAvg = avg(midFrequencies)
  const highFreqAvg = avg(highFrequencies)
  const lowMaxFreq = max(lowFrequencies)
  const lowMaxRatio = lowFrequencies.indexOf(lowMaxFreq) / lowFrequencies.length
  const midMaxFreq = max(midFrequencies)
  const midMaxRatio = midFrequencies.indexOf(midMaxFreq) / midFrequencies.length
  const highMaxFreq = max(highFrequencies)
  const highMaxRatio =
    highFrequencies.indexOf(highMaxFreq) / highFrequencies.length

  return {
    overallAvg,
    lowFreqAvg,
    midFreqAvg,
    highFreqAvg,
    lowMaxFreq,
    lowMaxRatio,
    midMaxFreq,
    midMaxRatio,
    highMaxFreq,
    highMaxRatio,
    colorSet: getColorSet(),
    // lowerMax,
    // lowerAvg,
    // upperMax,
    // upperAvg,
    // lowerMaxFr,
    // lowerAvgFr,
    // upperMaxFr,
    // upperAvgFr,
  }
}

export {
  fractionate,
  modulate,
  avg,
  max,
  parseAudioData,
  refreshAnalyserData,
  colorSets,
  getColorSet,
}
